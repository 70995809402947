import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Seo = ({ pageTitle, description, meta }) => {
    const { site } = useStaticQuery(
        graphql`
         query {
             site {
                 siteMetadata {
                     title
                     description
                 }
             }
         }
        `
    )
    const metaDescription = description || site.siteMetadata.description

    return (
        <Helmet
            htmlAttributes={{lang: 'en'}}
            title={pageTitle}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: pageTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
            ].concat(meta)}
        />
    )
}

export default Seo
