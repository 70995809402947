import React, { useContext } from "react"
import DataContext from "../data/context"
import Facebook from "../images/svgs/social/facebook.svg"
import Twitter from "../images/svgs/social/twitter.svg"
import Instagram from "../images/svgs/social/instagram.svg"
import Linkedin from "../images/svgs/social/linkedin.svg"

const Socials = () => {
  const { social } = useContext(DataContext)

  return (
    <div className="social-row">
      {social.facebook && (
        <a href={social.facebook} target="_blank" aria-label="Facebook">
          <Facebook />
        </a>
      )}
      {social.twitter && (
        <a href={social.twitter} target="_blank" aria-label="Twitter">
          <Twitter />
        </a>
      )}
      {social.instagram && (
        <a href={social.instagram} target="_blank" aria-label="Instagram">
          <Instagram />
        </a>
      )}
      {social.linkedin && (
        <a href={social.linkedin} target="_blank" aria-label="LinkedIn">
          <Linkedin />
        </a>
      )}
    </div>
  )
}

export default Socials
