import React, { useContext } from "react"
import { Link } from "gatsby"
import DataContext from '../../data/context'

const NavMenu = () => {
    const { navMenus } = useContext(DataContext)

    return (
        <div className="nav-menu">
            {navMenus.map(nav => (
                <div className="nav-item" key={nav.title}>
                    <Link to={nav.link} activeClassName="active">
                        {nav.title}
                    </Link>
                </div>
            ))}
        </div>
    )
}

export default NavMenu
