import React  from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import NavMenu from './NavMenu'
import { useToggle } from '../../utils/hooks'

const MobileNavMenu = () => {
    const [isExpanded, toggleExpanded] = useToggle(false)

    return (
        <div className='mobile-nav'>
            <FontAwesomeIcon icon={faBars} onClick={toggleExpanded} />
            <div className={isExpanded ? 'mobile-menu expanded' : 'mobile-menu'}>
                <FontAwesomeIcon icon={faArrowRight} onClick={toggleExpanded} />
                <NavMenu />
            </div>
        </div>
    )
}

export default MobileNavMenu