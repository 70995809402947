import React, { useState, useEffect } from 'react'
import NavMenu from './NavMenu'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import MobileNavMenu from './MobileNavMenu'

const SCROLL_MAX = 80

const Nav = ({ cssClass='' }) => {

    const [isScrolled, setIsScrolled] = useState(false)

    const handleScroll = () => {
        const currentScroll = window.scrollY
        if (currentScroll > SCROLL_MAX) {
            setIsScrolled(true)
        } else if (currentScroll < SCROLL_MAX) {
            setIsScrolled(false)
        }
    }

    useEffect(() => {
        if (window.scrollY > SCROLL_MAX) {
            setIsScrolled(true)
        }
        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
    }, [])

    return (
        <header>
            <header className={isScrolled ? cssClass + " scrolled" : cssClass}>
                <div className="nav">
                    <div className="logo">
                        <Link to="/">
                            <StaticImage
                                src="../../images/electric-web.svg"
                                width={220}
                                quality={95}
                                placeholder="blurred"
                                loading="eager"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="Electric Web Co Logo"
                            />
                        </Link>
                    </div>
                    <NavMenu />
                    <MobileNavMenu />
                </div>
            </header>
        </header>
    )
}

export default Nav
