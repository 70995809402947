const data = {
  navMenus: [
    {
      title: "Home",
      link: "/",
    }
  ],
  heroContent: {
    title: "San Antonio Web Design & Development",
    subTitle: "Are you ready to put the spark in your web presence?",
    message: "San Antonio Web Design & Development",
    button1: {
      text: "Learn More",
      link: "https://digibotweb.com",
    },
    button2: {
      text: "view packages",
      link: "https://digibotweb.com/pricing",
    },
  },
  prices: [
    {
      title: "Basic Monthly Package",
      price: "$99/ month",
      highlight: false,
      // description: 'Something something Basic something',
      includes: [
        "Up to 4 site pages",
        "Hosting",
        "Domain",
        "Design & Development of website",
        "Contact Form",
        "SSL Secured Site",
        "Maintenance & Support",
        "Site Backups",
      ],
      button: {
        text: "Get Started",
        link: "/contact?p=BASIC",
      },
    },
    {
      title: "Pro Monthly Package",
      price: "$149/ month",
      highlight: true,
      // description: 'Something something Premium something',
      includes: [
        "Up to 8 site pages",
        "Hosting",
        "Domain",
        "Design & Development of website",
        "Contact Form",
        "SSL Secured Site",
        "Maintenance & Support",
        "Site Backups",
        "Custom email address - 1 included additional $10/month",
        "2 Hours of site edits per month",
        "Monthly Site Analytic Reports",
      ],
      button: {
        text: "Get Started",
        link: "/contact?p=PRO",
      },
    },
    {
      title: "Custom Website Package",
      price: "One time fee",
      highlight: false,
      description: "Prefer to pay upfront? We have a fully customizable upfront package to reduce or completely remove your monthly costs",
      includes: [
        "Custom website design",
        "Development of website",
        "Customized to your needs",
        "Hosting & support plans available - For a small monthly fee"
      ],
      button: {
        text: "Get Started",
        link: "/contact?p=UPFRONT",
      },
    },
  ],
  allPackageItems: ["Lightning Fast", "Mobile Responsive", "SEO optimized", "Optimized for accessibility"],
  faqs: [
    {
      question: "What if I need to cancel my plan?",
      answer:
        "You can cancel anytime. We want you to stick around because of our great service and results, not because of a contractual obligation, which is why we have no minimum terms for our packages.",
    },
    {
      question: "What if I prefer a one time charge instead of a monthly package?",
      answer:
        "No problem. After a quick discovery round with you, we can provide you with an upfront quote for a one time cost for your website.",
    },
    {
      question: "Can I customize my monthly package?",
      answer:
        "Sure, while our monthly packages have been assembled to provide the best value for you, we are very much willing to put together a custom package to fit your needs.",
    },
    {
      question: "What if I need changes to my website?",
      answer:
        "While many of our packages include a monthly allowance of hours for site changes, we also have great rates for the occasional one off updates. We will also work with you to identify your reoccurring needs for site edits to utilize our flexible options to save you money.",
    },
    {
      question: "How do I request changes or edits to my website?",
      answer:
        "Just email us your requested changes to requests@electricwebco.com and we will promptly get back to you with the ETA for your changes to be live.",
    },
  ],
  steps: [
    {
      number: 1,
      title: "Planning & Design",
      description:
        "We will work with you to gather all your goals and requirements for your new site. We will then present you a design mockup for your approval of the basic page elements and layout.",
    },
    {
      number: 2,
      title: "Development",
      description:
        "We will code your website based on the designs and requirements. Once initial development is complete we will then present it to you for your revision rounds and approval.",
    },
    {
      number: 3,
      title: "Launch",
      description:
        "Your new website will be live! We will deploy your site to your new or existing domain for the world to find and see your business.",
    },
  ],
  benefits: [
    {
      left: {
        title: "Upfront Costs",
        description: "Predictable upfront costs with no hidden fees or charges",
      },
      right: {
        title: "No Minimum Obligation",
        description: "Cancel anytime with no hassle or fees",
      },
    },
    {
      left: {
        title: "All Services Included",
        description:
          "No need to worry about tracking multiple invoices and renewals for domains, hosting, and other services",
      },
      right: {
        title: "Support & Maintenance Included",
        description: "All packages include support and maintenance for all included services",
      },
    },
    {
      left: {
        title: "Website Updates & Changes",
        description: "Need a change to your site? No problem, just send us a message",
      },
      right: {
        title: "SEO Optimization",
        description: "All sites are optimized with the latest SEO techniques",
      },
    },
    {
      left: {
        title: "Security",
        description:
          "We develop our sites to be lean and secure to greatly reduce vulnerabilities compared to commonly used traditional website builder platforms",
      },
      right: {
        title: "Optimized for Accessibility",
        description:
          "Following all of the best practices not only makes you site accessible for everyone, but it will also improve your SEO rankings",
      },
    },
    {
      left: {
        title: "Hand Coded Websites",
        description:
          "We dont use Wordpress or other common page builders which allows us to create lightning fast flexible websites without being limited by the large bloated platforms",
      },
      right: {
        title: "Server Side Analytics",
        description:
          "Most analytic platforms utilize the users browser to relay data back to their platforms, but they can be blocked & inaccurate. We provide precise data directly from our hosting servers",
      },
    },
  ],
  location: "San Antonio, Texas",
  email: "hello@electricwebco.com",
  phone: "",
  footerServices: ["Web Design", "Web Development", "Hosting", "Maintenance & Support"],
  social: {
    facebook: "https://www.facebook.com/Electric.WebCo.tx",
    twitter: "https://twitter.com/electric_webco",
    instagram: "https://www.instagram.com/electric_webco",
  },
}

export default data
