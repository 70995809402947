import React, { useContext } from "react"
import NavMenu from "./nav/NavMenu"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import DataContext from "../data/context"
import Socials from "./Socials"

const Footer = ({ styleClass = "style-1" }) => {
  const { location, email, phone, footerServices, social } = useContext(DataContext)

  return (
    <section className={`footer ${styleClass}`}>
      <div className="content">
        <div className="footer-flex">
          <div className="footer-column">
            <StaticImage
              src="../images/electric-web.svg"
              width={380}
              quality={95}
              placeholder="blurred"
              loading="lazy"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="company logo"
            />
            {email && (
              <p>
                <FontAwesomeIcon icon="envelope" />
                <a href={`mailto:${email}`}>{email}</a>
              </p>
            )}
            {phone && (
              <p>
                <FontAwesomeIcon icon="phone" />
                <a href={`tel:${phone}`}>{phone}</a>
              </p>
            )}
            {location && (
              <p>
                <FontAwesomeIcon icon="map-marker-alt" />
                {location}
              </p>
            )}
            <Socials />
          </div>

          <div className="footer-column">
            <h3 className="column-title">Navigation</h3>
            <hr className="title-divider" />
            <NavMenu />
          </div>
          <div className="footer-column">
            <h3 className="column-title">Services</h3>
            <hr className="title-divider" />
            {footerServices.map(service => (
              <p key={service}>{service}</p>
            ))}
          </div>
          {/*<div className='footer-column'>*/}
          {/*    <h3 className='column-title'>Contact Us</h3>*/}
          {/*    <hr className='title-divider' />*/}
          {/*    {location && (<p><FontAwesomeIcon icon="map-marker-alt"/>{location}</p>)}*/}
          {/*    {email && (<p><FontAwesomeIcon icon="envelope"/><a href={`mailto:${email}`}>{email}</a></p>)}*/}
          {/*    {phone && (<p><FontAwesomeIcon icon="phone"/>{phone}</p>)}*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="copyright">Copyright © {new Date().getFullYear()} | Electric Web Co.</div>
    </section>
  )
}

export default Footer
