import React from "react"
import Seo from "./Seo"
import "@fontsource/inter"
import "@fontsource/inter/600.css"
import Nav from "./nav/Nav"
import DataContext from "../data/context"
import data from "../data"
import "../style/main.scss"
import Footer from "./Footer"

// Must import any used icons and add to library for dynamic usage
import { library } from "@fortawesome/fontawesome-svg-core"
import { faMapMarkerAlt, faClock, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"

library.add(faPhone, faClock, faMapMarkerAlt, faEnvelope)

const Layout = ({ pageTitle, description = "", meta = [], children }) => {
  return (
    <DataContext.Provider value={data}>
      <Seo pageTitle={pageTitle} description={description} meta={meta} />
      <Nav cssClass={"sticky"} />
      <main>{children}</main>
      <Footer styleClass={"style-2"} />
    </DataContext.Provider>
  )
}

export default Layout
